@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

:root{
    --main-color:#7550ba;
}
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: "Helvetica Neue", sans-serif;
    line-height: 1.5;
    position: absolute;

}

.small .img-panel{
    height: 25px;
}
.small .img{
    opacity: 0;
}
.small .title{
    background: #7550ba;
    color: white;
    font-weight: normal;
}
.small app-channel-component{
    margin: 0px;
    border: 1px solid #7550ba;
    min-width: initial;
}